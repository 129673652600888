import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="App Landing Page Template | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/startup-web-page-tablet-format/"
    metaDescription="Looking to design a landing page for a tablet app? Let Uizard's tablet app landing page template do the work for you."
    description="
    h2:Lando: An app landing page design template for tablet
    <br/>
    Lando is a robust and aesthetically attuned landing page design template optimized for tablets, designed to frame your MVP (Minimum Viable Product) stylishly and effectively. Lando offers a dynamic and fully customizable template that's easy to work with, whatever your use case may be.
    <br/>
    h3:An Iconic design aesthetic powered by the magic of Uizard
    <br/>
    As with most of our stunning <a:https://uizard.io/templates/>UI templates</a>, Lando is divided into sections that embody a simplistic and user-friendly design, that is focused on guiding your potential customers down the funnel - let's be honest, if you've got a great product, you want people to see it! The various sections of Lando are also easily duplicated and customized, meaning you can create something quickly that also reflects the personality of your MVP.
    <br/>
    h3:Take the next step; champion your MVP with Lando for tablet
    <br/>
    Create and edit the content of your startup landing page design prototype with Uizard's handy template. Simply open the template, put your own unique spin on it with drag-and-drop components, and showcase your MVP in minutes - we've made startup landing page design quick and simple with Lando.
    "
    pages={[
      "A startup landing page template for tablets boasting multiple useful sections including the main banner for your MVP (minimum viable product), testimonials, and a call-to-action",
      "A pricing screen with different pricing tiers, as well as an FAQ template, and a contact us page to demonstrate your customer-minded approach",
      'An "About Us" screen that features your story, your mission, and your team',
    ]}
    projectCode="oaJ4Laxg1ZCo5gMbOBa9"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of Lando's startup web page optimized for tablet: home screen"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of Lando's startup web page optimized for tablet: about us screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of Lando's startup web page optimized for tablet: contact screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of Lando's startup web page optimized for tablet: pricing screen"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of startup web page optimized for tablet Lando template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/lando-tablet/lando-tablet-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/lando-tablet/lando-tablet-about-us.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/lando-tablet/lando-tablet-contact.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/lando-tablet/lando-tablet-pricing.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/lando-tablet/lando-tablet-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
